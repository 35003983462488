import React from 'react'
import Global from '../Global/Global';

export default function Menulist({social, menu, rightArray}) {

    const {empty, urls} = Global();
    const logoLink = "/";
  const logoImage = urls.assets + social.menu_logoold;
    const menuItems = menu
    

  const rightMenuItems = rightArray;

  return (
    
    <div className="myNewMenu WithoutSearch">
      <div className="menuGrid grid maxWidth1500 float-center width96 alignCenter relative">
        {/* Logo */}
        <div>
          <a href={logoLink} title="Welcome to Pari Foundation" className="newPcLogo">
            <img src={logoImage} alt="Welcome to Pari Foundation" />
          </a>
        </div>

        {/* Main Menu */}
        <div>
          <ul className="newMenuList justifycenter lighnHover">
            {menuItems.map((item, index) => (
              <li key={index} className="smallHover">
                <a href={item.link} title={item.title}>
                  <span>{item.title}</span>
                </a>
                {item.dropdown && (
                  <>
                    <div className="dropDownArrow">
                      <i className="fas fa-caret-down"></i>
                    </div>
                    <div className="newSmallDropDown">
                      <ul className="newMenuList newDowpDown">
                        {item.dropdown.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <a href={subItem.link} title={subItem.title}>
                              <span>{subItem.title}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Right Menu */}
        <div>
          <ul className="newMenuList justifyRight newMenuHoverEffect">
            <li className="smallHover activeMenu">
                <a href={'/login'} title={'Login'}>
                    <div className="newMenuIcon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-user"
                            >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                    </div>
                    <span> {!empty(localStorage.getItem('userid')) ? 'Hi, Donor' : 'Login'} </span>
                </a>
                </li>
            {rightMenuItems.map((item, index) => (
              <li key={index} className={"smallHover activeMenu breathe"}>
                <a href={item.link} title={item.title}>
                    {
                        !empty(item.imgicon) &&
                        <div className="newMenuIcon"><img src={ urls.assets + item.imgicon} alt={item.title} /></div>
                    }
                  <span>{item.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
